import React from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  Box,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AssessmentIcon from "@mui/icons-material/Assessment";

const OnsiteModule = () => {
  // Updated group data with an additional card for Onsite Report
  const groups = [
    {
      title: "Group 1: Onsite Registration",
      description:
        "Includes full management of onsite visitor registration and printing of badges directly at the event venue.",
      color: "#1e88e5",
    },
    {
      title: "Group 2: Security Scanning",
      description:
        "Provides a secure scanning link for verifying the authenticity and access permissions of each badge holder.",
      color: "#8e24aa",
    },
    {
      title: "Group 3: Kiosk Badge Printing",
      description:
        "Enables quick self-service badge printing using dedicated kiosks for faster check-in.",
      color: "#d81b60",
    },
    {
      title: "Group 4: Bulk Printing Options",
      description:
        "Facilitates bulk printing of badges for large groups, making it ideal for managing exhibitor teams and special guests.",
      color: "#43a047",
    },
    {
      title: "Onsite Report: Footfall Analysis",
      description:
        "Provides real-time insights and detailed analytics on the footfall patterns and visitor engagement throughout the event.",
      color: "#f4511e",
    },

    {
      title: "Group 6: Search and Print",
      description:
        "Allows quick lookup and printing of badges for walk-in visitors and last-minute registrations.",
      color: "#3949ab",
    },
    {
      title: "Group 5: Exhibitor Service Badges",
      description:
        "Provides badges specifically designed for exhibitors and service providers with unique access privileges.",
      color: "#fb8c00",
    },
  ];

  return (
    <Container maxWidth="lg" style={{ marginTop: "40px" }}>
      <Grid container spacing={4}>
        {groups.map((group, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              elevation={6}
              sx={{
                borderRadius: "12px",
                transition: "0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
                borderLeft: `6px solid ${group.color}`,
              }}
            >
              <CardContent>
                <Box display="flex" alignItems="center" mb={1}>
                  {index === groups.length - 1 ? (
                    <AssessmentIcon sx={{ color: group.color, mr: 1 }} />
                  ) : (
                    <CheckCircleOutlineIcon
                      sx={{ color: group.color, mr: 1 }}
                    />
                  )}
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    {group.title}
                  </Typography>
                </Box>
                <Typography variant="body1" color="textSecondary" mb={2}>
                  {group.description}
                </Typography>
              </CardContent>
              <CardActions
                sx={{
                  justifyContent: "flex-end",
                  paddingRight: "16px",
                  paddingBottom: "16px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<InfoIcon />}
                >
                  Activate
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default OnsiteModule;

import { Cropper } from "react-cropper";
import { useRef, useState } from "react";
import "cropperjs/dist/cropper.css";
import axios from "axios";
import { toast } from "react-toastify";
import { Grid, Button, Typography, Input, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ADMIN, API_ENDPOINT } from "../../utils/constants";
import { getMultipartHeader, toastError } from "../../utils/util";
export const MAP_IMAGE = "map_image";
export const HALL_IMAGE = "hall_image";
function FpCropEdit({ upload_type, aspect_ratio = 3, hallId, show, setShow }) {
  const cropperRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState(null);

  const onInputChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const cancelCrop = () => {
    document.location.reload();
  };

  const uploadImage = async () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const canvas = cropper.getCroppedCanvas();
    const blob = await getCanvasBlob(canvas, "image/png");
    const sizeInKB = blob.size / 1024;
    if (sizeInKB > 2048) {
      toast.error(
        `Image size is more than 2MB. Please choose a smaller image. Your size is ${(
          sizeInKB / 1024
        ).toFixed(2)}MB.`
      );
      return;
    }
    let url = "";
    let formData = new FormData();
    formData.append("image", blob, "myimage.png");
    if (upload_type === HALL_IMAGE) {
      url = `${API_ENDPOINT}user/update-hall-image`;
      formData.append("hallId", hallId);
    } else {
      url = `${API_ENDPOINT}user/update-floor`;
      formData.append("eventId", "1");
    }
    setUploading(true);
    try {
      const { data } = await axios.post(
        url,
        formData,
        getMultipartHeader(ADMIN)
      );
      toast.success(data.message);
    } catch (error) {
      toastError(error);
    } finally {
      setUploading(false);
    }
  };
  const getCanvasBlob = (canvas, type) =>
    new Promise((resolve) => canvas.toBlob(resolve, type));
  return (
    <Box sx={{ height: "70vh", position: "relative" }}>
      <Box
        sx={{
          display: "table",
          color: "#777",
          fontSize: "14px",
          padding: "8px",
          border: "1px dotted #ddd",
          mb: 1,
        }}
      >
        <Input
          type="file"
          inputProps={{ accept: "image/jpeg, image/png, image/jpg" }}
          onChange={onInputChange}
        />
        <Typography variant="body2">Max size: 2MB</Typography>
        <Typography variant="body2">
          Supported Formats: PNG, JPEG, JPG
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Cropper
            src={image}
            style={{ height: 300, width: "100%" }}
            viewMode={1}
            preview=".img-preview"
            ref={cropperRef}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography align="center">Preview</Typography>
          <div
            className="img-preview"
            style={{
              width: "100%",
              height: "172px",
              overflow: "hidden",
              display: "inline-block",
            }}
          ></div>
        </Grid>
      </Grid>

      <Box
        sx={{
          position: "absolute",
          bottom: 2,
          display: "flex",
          right: 2,
          gap: 4,
        }}
      >
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={cancelCrop}
        >
          Cancel
        </Button>
        <LoadingButton
          size="small"
          loading={uploading}
          variant="contained"
          color="primary"
          onClick={uploadImage}
        >
          Upload
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default FpCropEdit;

import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import tinycolor from "tinycolor2";
import * as XLSX from "xlsx";

export function toastError(error) {
  toast.error(getErrorResponse(error));
}
export function getErrorResponse(error) {
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  } else {
    return "An error occurred. Please try again.";
  }
}
export const getDbHeader = () => {
  return {
    headers: {
      "content-type": "application/json",
      dbauth:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYiI6ImltdGV4MjAyNSIsImlhdCI6MTcyNjU3MTk1MywiZXhwIjoxNzU4MTI5NTUzfQ.aKdbfdfptzXxKuYofjEDO7o4ckLUn0Q0ILZz_0lZ8Ls", //db: testapp
    },
  };
};
export const saveUser = (role, data) => {
  localStorage.setItem(role, JSON.stringify(data));
};
export const getUser = (role) => {
  return JSON.parse(localStorage.getItem(role));
};
export const deleteUser = (role) => {
  localStorage.removeItem(role);
};
export const isUserLoggedIn = (role) => {
  return localStorage.getItem(role) !== null;
};
export function navToElementMarker(elem, msg) {
  if (elem.current) {
    elem.current.scrollIntoView({ behavior: "smooth", block: "center" });
    elem.current.parentElement.style.border = "1px solid red";
    setTimeout(() => {
      elem.current.parentElement.style.border = ""; // Revert to the original style
    }, 3000);
    toast.error(msg);
  }
}
export const UserAuth = ({ children, type }) => {
  return isUserLoggedIn(type) ? children : <Navigate to="/login" />;
};
export const formatDateMMMddYYYY = (dateString) => {
  const date = new Date(dateString);
  const options = { year: "numeric", month: "short", day: "numeric" };
  return date.toLocaleDateString("en-GB", options).replace(",", "");
};
export const getHeader = (type) => {
  return {
    headers: {
      "content-type": "application/json",
      token: getUser(type)?.token,
    },
  };
};
export const getMultipartHeader = (type) => {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      token: getUser(type)?.token,
    },
  };
};
export function getHoverColor(baseColor) {
  const hoverColor = tinycolor(baseColor).brighten(10).toString();
  return hoverColor;
}
export function getContrastingTextColor(hexColor) {
  const color = new tinycolor(hexColor);
  return color.isLight() ? "black" : "white";
}
export function hexToRGBA(hex, alpha = 1) {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
export function formatMongoDate(isoDateString) {
  const date = new Date(isoDateString);
  const day = date.getUTCDate();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getUTCMonth()];
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format
  return `${day} ${month} ${hours}:${minutes}${ampm}`;
}
export function extractTime(isoString) {
  const dateObj = new Date(isoString);
  return dateObj.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
}
export function extractDate(isoString) {
  const dateObj = new Date(isoString);
  return dateObj.toLocaleDateString("en-GB"); // en-GB format is dd/mm/yyyy
}
export function roundToTwoDecimals(num) {
  return parseFloat(num.toFixed(2));
}

export const exportToExcel = (data, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

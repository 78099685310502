import { useQuery } from "react-query";
import axios from "axios";
import { getHeader } from "../utils/util";
import { ADMIN } from "../utils/constants";
const doAction = async (path) => {
  try {
    const res = await axios.get(path, getHeader(ADMIN));
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

const useCommonGetInit = (path) => {
  return useQuery(["com-init-get", path], () => doAction(path), {
    staleTime: 1 * 60 * 1000,
    retry: 0,
  });
};

export default useCommonGetInit;

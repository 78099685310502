import React, { useState, useMemo } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import { scaleLinear } from "d3-scale";
import IconButton from "@mui/material/IconButton";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTheme } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import { geoCentroid } from "d3-geo";
import { API_ENDPOINT } from "../utils/constants";
import useCommonGetInit from "../hooks/useCommonGetInit";

const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";

const WorldMapChart = () => {
  const theme = useTheme();
  const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1 });
  const [tooltipContent, setTooltipContent] = useState("");

  const { data: countrywiseData, isLoading: isCountrywiseLoading } =
    useCommonGetInit(`${API_ENDPOINT}user/a/get-countrywise-registrations`);

  // Prepare data for mapping
  const dataByCountry = useMemo(() => {
    if (!countrywiseData || !Array.isArray(countrywiseData.registrations))
      return [];

    return countrywiseData.registrations.map((item) => ({
      country: item.country,
      count: item.count,
      flag: item.countryDetails.flag,
    }));
  }, [countrywiseData]);

  if (isCountrywiseLoading) {
    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  // Max count to scale marker sizes appropriately
  const maxCount = Math.max(...dataByCountry.map((d) => d.count));

  // Update markerScale domain based on maxCount
  const markerSizeScale = scaleLinear().domain([0, maxCount]).range([5, 20]);

  // Create a mapping of country names to registration counts
  const countryDataMap = dataByCountry.reduce((acc, item) => {
    acc[item.country?.toLowerCase()] = item;
    return acc;
  }, {});

  // Function to normalize country names
  const normalizeCountryName = (name) => {
    return name?.toLowerCase();
  };

  // Handle zoom and pan
  const handleZoomIn = () => {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 1.5 }));
  };

  const handleZoomOut = () => {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 1.5 }));
  };

  const handleMoveEnd = (newPosition) => {
    setPosition(newPosition);
  };

  return (
    <div style={{ position: "relative" }}>
      {/* Zoom buttons */}
      <div style={{ position: "absolute", top: 10, right: 10, zIndex: 1000 }}>
        <IconButton
          onClick={handleZoomIn}
          color="primary"
          aria-label="zoom in"
          style={{ margin: "5px" }}
        >
          <ZoomInIcon />
        </IconButton>
        <IconButton
          onClick={handleZoomOut}
          color="primary"
          aria-label="zoom out"
          style={{ margin: "5px" }}
        >
          <ZoomOutIcon />
        </IconButton>
      </div>

      <ComposableMap
        projectionConfig={{ scale: 120 }}
        width={800}
        height={312}
        data-tip=""
        style={{ width: "100%", height: "auto" }}
      >
        <ZoomableGroup
          center={position.coordinates}
          zoom={position.zoom}
          onMoveEnd={handleMoveEnd}
          minZoom={1}
          maxZoom={4}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) => (
              <>
                {geographies.map((geo) => {
                  const countryName = geo.properties.name;
                  const countryKey = normalizeCountryName(countryName);

                  // Check if this country is in our data
                  const isInData = countryDataMap[countryKey];

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={
                        isInData
                          ? theme.palette.primary.light
                          : theme.palette.action.hover
                      }
                      stroke={theme.palette.divider}
                      style={{
                        default: { outline: "none" },
                        hover: {
                          fill: theme.palette.primary.main,
                          outline: "none",
                        },
                        pressed: { outline: "none" },
                      }}
                      onMouseEnter={() => {
                        setTooltipContent(countryName);
                      }}
                      onMouseLeave={() => {
                        setTooltipContent("");
                      }}
                    />
                  );
                })}
                {geographies.map((geo) => {
                  // Get country name from TopoJSON properties
                  const countryName = geo.properties.name;
                  const countryKey = normalizeCountryName(countryName);

                  // Check if this country is in our data
                  if (countryDataMap[countryKey]) {
                    const { count } = countryDataMap[countryKey];
                    const centroid = geoCentroid(geo);

                    return (
                      <Marker key={geo.rsmKey} coordinates={centroid}>
                        <circle
                          r={markerSizeScale(count)}
                          fill={theme.palette.secondary.main}
                          stroke={theme.palette.secondary.dark}
                          strokeWidth={1}
                          onMouseEnter={() => {
                            setTooltipContent(
                              `${countryName}: ${count} visitors`
                            );
                          }}
                          onMouseLeave={() => {
                            setTooltipContent("");
                          }}
                        />
                      </Marker>
                    );
                  }
                  return null;
                })}
              </>
            )}
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <ReactTooltip>{tooltipContent}</ReactTooltip>
    </div>
  );
};

export default WorldMapChart;

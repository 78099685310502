import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import {
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Container,
  Grid,
  Stack,
  Divider,
  Alert,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import BackBtn from "../../widgets/BackBtn";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import DownloadIcon from "@mui/icons-material/Download";
import { saveAs } from "file-saver";
import { ScrollTableContainer } from "../../components/visitors/Visitors";
import { LoadingButton } from "@mui/lab";
import { getHeader, toastError } from "../../utils/util";
import axios from "axios";
import { ADMIN, API_ENDPOINT } from "../../utils/constants";
import { toast } from "react-toastify";
export const EXHIBITOR_TEMPLATE =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/exhibitor_template.xlsx";
function ExhibitorExcelReader() {
  const [excelData, setExcelData] = useState([]);
  const [saving, setSaving] = useState(false);

  function excelDateToJSDate(serial) {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    return new Date(utc_value * 1000).toLocaleDateString();
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Get all rows, including headers
      const jsonData = XLSX.utils.sheet_to_json(sheet, {
        header: 1, // Return as an array of arrays, including headers
        defval: "", // Default value for empty cells
      });

      if (jsonData.length > 0) {
        // Extract headers and map data rows
        const headers = jsonData[0];
        const dataRows = jsonData.slice(1);

        const formattedData = dataRows.map((row) => {
          const obj = {};
          headers.forEach((header, index) => {
            obj[header] = row[index] !== undefined ? row[index] : "";
          });
          obj["Product Categories"] = [];
          obj["Exhibit Arrival"] = obj["Exhibit Arrival"]
            ? excelDateToJSDate(obj["Exhibit Arrival"])
            : "";
          obj["Material Arrival"] = obj["Material Arrival"]
            ? excelDateToJSDate(obj["Material Arrival"])
            : "";
          obj["Dismantling"] = obj["Dismantling"]
            ? excelDateToJSDate(obj["Dismantling"])
            : "";
          obj["Stand Size"] = obj["Stand Size"]
            ? parseInt(obj["Stand Size"])
            : "";
          obj["Pin/Zip"] = obj["Pin/Zip"] ? `${obj["Pin/Zip"]}` : "";
          return obj;
        });
        setExcelData(formattedData);
      } else {
        console.log("The uploaded file has no content.");
        setExcelData([]);
      }
    };
    reader.readAsBinaryString(file);
  };

  const saveList = async () => {
    if (excelData.length === 0) {
      toast.error("No data found!");
      return;
    }
    setSaving(true);
    try {
      const { data } = await axios.post(
        `${API_ENDPOINT}user/a/add-exhibitors-from-excel`,
        excelData,
        getHeader(ADMIN)
      );
      toast.success(data.message);
      setTimeout(() => {
        document.location.reload();
      }, 800);
    } catch (error) {
      toastError(error);
    } finally {
      setSaving(false);
    }
  };
  const onCancel = () => {
    document.location.reload();
  };
  return (
    <Box>
      <BackBtn />
      <Container>
        <Typography variant="h6" gutterBottom>
          Import Exhibitor List from Excel File
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Button
              startIcon={<ImportExportIcon />}
              variant="contained"
              component="label"
              sx={{ marginBottom: 2 }}
            >
              Import excel File
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
                hidden
              />
            </Button>
          </Grid>
          <Grid item sm={6}>
            <Button
              startIcon={<DownloadIcon />}
              variant="outlined"
              component="label"
              sx={{ marginBottom: 2 }}
              onClick={() => {
                saveAs(EXHIBITOR_TEMPLATE, "exhibitor_template.xlsx");
              }}
            >
              Download excel template
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
                hidden
              />
            </Button>
          </Grid>
        </Grid>

        {excelData.length === 0 && (
          <Stack gap={2} direction="column">
            <Typography variant="h6" gutterBottom>
              Some points to consider before uploading:
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="1. Your excel should contain the columns shown in the template." />
              </ListItem>
              <ListItem>
                <ListItemText primary="2. At least one line entry should be there in the sheet." />
              </ListItem>
              <ListItem>
                <ListItemText primary="3. You should maintain the exhibitor ID yourself by assigning a unique ID to each exhibitor." />
              </ListItem>
            </List>
          </Stack>
        )}
        {excelData.length > 0 && (
          <>
            <Typography gutterBottom variant="body1">
              Please review the following list before saving the list to the
              database:
            </Typography>
            <Divider />
          </>
        )}
        <ScrollTableContainer>
          {excelData.length > 0 && (
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {Object.keys(excelData[0]).map((key) => (
                    <TableCell key={key} sx={{ fontWeight: "bold" }}>
                      {key}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {excelData.map((row, index) => (
                  <TableRow key={index}>
                    {Object.values(row).map((value, idx) => (
                      <TableCell key={idx}>{value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </ScrollTableContainer>

        {excelData.length > 0 && (
          <>
            {" "}
            <Typography mt={2} mb={2} variant="body1">
              If the contents in the list are correct, then you can proceed to
              save the list:
            </Typography>
            <Stack direction={"row"} gap={2}>
              <Button onClick={onCancel} variant="outlined">
                Cancel
              </Button>
              <LoadingButton
                onClick={saveList}
                loading={saving}
                variant="contained"
              >
                Save List
              </LoadingButton>
            </Stack>
          </>
        )}
      </Container>
    </Box>
  );
}

export default ExhibitorExcelReader;

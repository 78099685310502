import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  CardContent,
  Card,
  List,
  Button,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useNavigate } from "react-router-dom";
import { Group, Image, Layer, Line, Rect, Stage } from "react-konva";
import { toast } from "react-toastify";
import FpCropEdit, { MAP_IMAGE } from "./FpCropEditor";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import SimpleYesNoDialog from "../../widgets/SimpleYesNoDialog";
import CustomCircularP from "../../widgets/CustomCircularP";
import BackBtn from "../../widgets/BackBtn";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { ADMIN, API_ENDPOINT } from "../../utils/constants";
import RdMyModal from "../../widgets/myModal/RdMyModal";
import {
  getHeader,
  getHoverColor,
  hexToRGBA,
  toastError,
} from "../../utils/util";
import MuPb from "../../widgets/MuPb";
const TRANSPARENCY = 0.8;
export const CONTAINER_WIDTH = 1280;
export const CONTAINER_HEIGHT = 560;
export const CONTAINER_AR = 1280 / 600;
function GeneralFloorPlan() {
  const [realodFp, setReloadFp] = useState(false);
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-floor?id=1&r=${realodFp}`
  );
  const navigate = useNavigate();
  const [showImageCrop, setShowImageCrop] = useState(false);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [image, setImage] = useState(null);
  const [gMenuEl, setGmenuEl] = useState(null);
  const [hMenuEl, setHmenuEl] = useState(null);
  const [stageScale, setStageScale] = useState(1);
  const [stagePos, setStagePos] = useState({ x: 0, y: 0 });
  const [newHalls, setNewHalls] = useState();
  const [showHallDelete, setShowHallDelete] = useState(false);
  const [showFloorDelete, setShowFloorDelete] = useState(false);
  const [targetHall, setTargetHall] = useState();
  const [hallDeleting, setHallDeleting] = useState(false);
  const [floorDeleting, setFloorDeleting] = useState(false);
  const [confirmAllDelete, setConfirmAllDelete] = useState(false);

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.05;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();

    const pointer = stage.getPointerPosition();

    // Calculate the new scale
    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    // Update the stage scale
    setStageScale(newScale);

    // Calculate the new position
    const newPos = {
      x: pointer.x - ((pointer.x - stage.x()) * newScale) / oldScale,
      y: pointer.y - ((pointer.y - stage.y()) * newScale) / oldScale,
    };

    setStagePos(newPos); // Update the stage position
    // setStageX(newPos.x); // Assuming these are used for something else
    // setStageY(newPos.y);
  };
  function refetchFloorPlan() {
    setReloadFp((prev) => !prev);
  }

  useEffect(() => {
    if (data) {
      const img = new window.Image();
      img.src = data.image;

      img.onload = () => {
        setImage({
          img: img,
          width: img.width,
          height: img.height,
        });

        if (containerRef.current) {
          const { width, height } =
            containerRef.current.getBoundingClientRect();

          const originalWidth = CONTAINER_WIDTH;
          const originalHeight = CONTAINER_WIDTH * (img.height / img.width);
          const newHeight = width * (img.height / img.width);
          setDimensions({
            width,
            height: newHeight,
          });
          const halls = [...data.halls];
          const scaleX = width / originalWidth;
          const scaleY = newHeight / originalHeight;
          halls.forEach((hall) => {
            if (hall.shape.type === "Polygon") {
              // Scale polygon points
              hall.shape.shape = hall.shape.shape.map((point, index) => {
                return index % 2 === 0 ? point * scaleX : point * scaleY;
              });
            } else if (hall.shape.type === "Rectangle") {
              // Scale rectangle properties
              hall.shape.shape.x *= scaleX;
              hall.shape.shape.y *= scaleY;
              hall.shape.shape.width *= scaleX;
              hall.shape.shape.height *= scaleY;
            }
          });
          setNewHalls(halls);
        }
      };
    }
  }, [data]);

  const hanldeHallMenuClick = (e, hall) => {
    setTargetHall(hall);
    setHmenuEl(e.target);
  };
  const handleGeneralPlanEdit = (e) => {
    setGmenuEl(e.target);
  };
  const handleGmenuClick = (event, action) => {
    setGmenuEl(null); // Close the menu

    switch (action) {
      case "Edit Image":
        setShowImageCrop(true);
        break;

      case "Replace Image":
        setShowImageCrop(true);
        break;

      case "Delete Image":
        setShowFloorDelete(true);
        break;

      default:
        console.warn("Unknown action:", action);
        break;
    }
  };
  const addNewHall = () => {
    navigate("/home/fp-add-hall");
  };

  const handleHmenuClick = (e, m) => {
    setHmenuEl(null); // Close the menu
    switch (m) {
      case "Edit Hall":
        navigate(`/home/edit-hall/${targetHall._id}`);
        break;

      case "Delete Hall":
        setShowHallDelete(true);
        break;

      default:
        console.warn("Unknown action:", m);
        break;
    }
  };
  const handleHallDelete = async (id) => {
    setHallDeleting(true);
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}floorplan/delete-hall?id=${id}`,
        getHeader(ADMIN)
      );
      setHallDeleting(false);
      refetchFloorPlan();
      toast.success(response.data.message);
    } catch (error) {
      setHallDeleting(false);
      toastError(error);
    }
  };
  const handleFloorDelete = async (id) => {
    setFloorDeleting(true);
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}user/delete-floor?id=${id}`,
        getHeader(ADMIN)
      );
      setFloorDeleting(false);
      toast.success(response.data.message);
      setTimeout(() => {
        document.location.reload();
      }, 500);
    } catch (error) {
      toastError(error);
    } finally {
      setFloorDeleting(false);
    }
  };
  const onYesHallDelete = () => {
    setShowHallDelete(false);
    handleHallDelete(targetHall._id);
  };
  const onYesFloorDelete = () => {
    setShowFloorDelete(false);
    handleFloorDelete(data?._id);
  };
  const onYesDeleteAllHalls = async () => {
    setHallDeleting(true);
    try {
      const { data } = await axios.delete(
        `${API_ENDPOINT}user/delete-all-halls?id=${data?.eventId}`,
        getHeader(ADMIN)
      );
      toast.success(data.message);
      setTimeout(() => {
        document.location.reload();
      }, 500);
    } catch (error) {
      toastError(error);
    } finally {
      setHallDeleting(false);
    }
    setConfirmAllDelete(false);
  };
  return (
    <Box sx={{ maxWidth: "1280px", ml: "auto", mr: "auto", mt: 2 }}>
      <CustomCircularP show={isLoading || hallDeleting} />
      <RdMyModal
        showModal={showImageCrop}
        setShowModal={setShowImageCrop}
        modalC={
          <FpCropEdit
            reloadFn={refetchFloorPlan}
            show={showImageCrop}
            setShow={setShowImageCrop}
            upload_type={MAP_IMAGE}
          />
        }
      />
      <CustomCircularP show={hallDeleting} />

      <SimpleYesNoDialog
        title="Please Confirm!"
        message={`Are you sure you want to delete all halls?`}
        onYes={onYesDeleteAllHalls}
        show={confirmAllDelete}
        setShow={setConfirmAllDelete}
      />
      <SimpleYesNoDialog
        title="Please Confirm!"
        message={`Are you sure you want to delete this Map?`}
        onYes={onYesFloorDelete}
        show={showFloorDelete}
        setShow={setShowFloorDelete}
      />
      <SimpleYesNoDialog
        title="Please Confirm!"
        message={`Are you sure you want to delete ${targetHall?.name}?. Please beware that all data releated to this hall will be deleted.`}
        onYes={onYesHallDelete}
        show={showHallDelete}
        setShow={setShowHallDelete}
      />
      <BackBtn />
      <Menu
        anchorEl={gMenuEl}
        open={Boolean(gMenuEl)}
        onClose={() => setGmenuEl(null)}
      >
        {/* <MenuItem
          disabled={!image}
          onClick={(e) => handleGmenuClick(e, "Edit Image")}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Edit Image
        </MenuItem> */}
        <MenuItem
          disabled={!image}
          onClick={(e) => handleGmenuClick(e, "Replace Image")}
        >
          <ListItemIcon>
            <FindReplaceIcon fontSize="small" />
          </ListItemIcon>
          Replace Image
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={!image}
          sx={{ color: "red" }}
          onClick={(e) => handleGmenuClick(e, "Delete Image")}
        >
          <ListItemIcon>
            <DeleteOutlineIcon sx={{ color: "red" }} fontSize="small" />
          </ListItemIcon>
          Delete Image
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={hMenuEl}
        open={Boolean(hMenuEl)}
        onClose={() => setHmenuEl(null)}
      >
        <MenuItem onClick={(e) => handleHmenuClick(e, "Edit Hall")}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Edit Hall
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ color: "red" }}
          onClick={(e) => handleHmenuClick(e, "Delete Hall")}
        >
          <ListItemIcon>
            <DeleteOutlineIcon sx={{ color: "red" }} fontSize="small" />
          </ListItemIcon>
          Delete Hall
        </MenuItem>
      </Menu>

      <Grid marginTop={1} container spacing={2}>
        <Grid item xs={8}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  // Adjust as per your needs
                }}
              >
                <Typography variant="h6">General Plan</Typography>

                <IconButton
                  onClick={handleGeneralPlanEdit}
                  color="primary"
                  aria-label="edit"
                >
                  <EditIcon />
                </IconButton>
              </Box>
              {data?.image ? (
                <Card
                  ref={containerRef}
                  sx={{
                    height: `${CONTAINER_HEIGHT - 100}px`,
                    overflow: "hidden",
                    position: "relative",
                  }}
                  variant="outlined"
                >
                  {image ? (
                    <Stage
                      width={dimensions.width}
                      height={CONTAINER_HEIGHT - 100}
                      scaleX={stageScale}
                      scaleY={stageScale}
                      x={stagePos.x}
                      y={stagePos.y}
                      draggable={true}
                      onWheel={handleWheel}
                    >
                      <Layer>
                        <Image
                          image={image.img}
                          width={dimensions.width}
                          height={dimensions.height}
                          x={(dimensions.width - dimensions.width) / 2} // Center horizontally
                          y={(CONTAINER_HEIGHT - 203 - dimensions.height) / 2}
                        />
                        {newHalls
                          ?.filter((hall) => hall.shape.type === "Polygon")
                          .map((hall) => (
                            <Group>
                              <Line
                                points={hall.shape.shape}
                                fill={hexToRGBA(hall.color, TRANSPARENCY)}
                                stroke={hexToRGBA(hall.color, TRANSPARENCY)}
                                strokeWidth={hall.strokeWidth}
                                closed={true}
                                onClick={() => {
                                  navigate(`/hall-plan/${hall._id}`);
                                }}
                                onMouseEnter={(e) => {
                                  const shape = e.target;
                                  shape.fill(getHoverColor(hall.color)); // Change fill to a darker blue on hover
                                  shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                                }}
                                onMouseLeave={(e) => {
                                  const shape = e.target;
                                  shape.fill(
                                    hexToRGBA(hall.color, TRANSPARENCY)
                                  ); // Reset to the original color on mouse leave
                                  shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                                }}
                              />
                            </Group>
                          ))}
                        {newHalls
                          ?.filter((hall) => hall.shape.type === "Rectangle")
                          .map((hall) => (
                            <Rect
                              x={hall.shape.shape.x}
                              y={hall.shape.shape.y}
                              width={hall.shape.shape.width}
                              height={hall.shape.shape.height}
                              fill={hexToRGBA(hall.color, TRANSPARENCY)}
                              onClick={(e) => {
                                navigate(`/hall-plan/${hall._id}`);
                              }}
                              onMouseEnter={(e) => {
                                const shape = e.target;
                                shape.fill(getHoverColor(hall.color)); // Change fill to a darker blue on hover
                                shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                              }}
                              onMouseLeave={(e) => {
                                const shape = e.target;
                                shape.fill(hexToRGBA(hall.color, TRANSPARENCY)); // Reset to the original color on mouse leave
                                shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                              }}
                            />
                          ))}
                      </Layer>
                    </Stage>
                  ) : (
                    <Box sx={{ p: 3, width: "100%", height: "100%" }}>
                      Loading ...
                    </Box>
                  )}
                </Card>
              ) : (
                <Box
                  onClick={(event) => {
                    setShowImageCrop(true);
                  }}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    border: "2px dashed #054059",
                    borderRadius: 1,
                    padding: 4,
                    cursor: "pointer",
                  }}
                >
                  <Typography variant="h6" color="#054059">
                    Click to upload Map
                  </Typography>
                  <Typography variant="body2">
                    Only images (JPG/JPEG, PNG) less than 2mb are allowed for
                    upload.
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent>
              <Box>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // Adjust as per your needs
                  }}
                >
                  <Typography variant="h6">Hall List</Typography>

                  <Box display={"flex"} gap={1}>
                    <LoadingButton
                      sx={{ textTransform: "none" }}
                      startIcon={<DeleteOutlineIcon />}
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setConfirmAllDelete(true);
                      }}
                    >
                      Delete All Halls
                    </LoadingButton>
                    <Button
                      sx={{ textTransform: "none" }}
                      color="primary"
                      startIcon={<AddCircleOutlineIcon />}
                      size="small"
                      variant="contained"
                      onClick={addNewHall}
                    >
                      Add New Hall
                    </Button>
                  </Box>
                </Box>

                <Typography variant="caption" gutterBottom>
                  EXHIBITION HALLS
                </Typography>

                <List>
                  {data && data.halls.length > 0 ? (
                    data.halls.map((hall, index) => (
                      <Card
                        key={index}
                        variant="outlined"
                        sx={{ padding: 1, mt: 1 }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            // Adjust as per your needs
                          }}
                        >
                          <Typography variant="body1">{hall.name}</Typography>
                          <IconButton
                            onClick={(e) => hanldeHallMenuClick(e, hall)}
                            size="small"
                            aria-label="edit"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </Box>
                      </Card>
                    ))
                  ) : (
                    <Typography
                      variant="h6"
                      sx={{ mt: 3, textAlign: "center" }}
                    >
                      No halls available.
                    </Typography>
                  )}
                </List>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default GeneralFloorPlan;

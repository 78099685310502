import React, { useEffect, useRef, useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link as RouterLink } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExploreIcon from "@mui/icons-material/Explore";
import GroupIcon from "@mui/icons-material/Group";
import CategoryIcon from "@mui/icons-material/Category";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MapIcon from "@mui/icons-material/Map";
import ServicesIcon from "@mui/icons-material/Build";
import BookIcon from "@mui/icons-material/Book";
import NaturePeopleIcon from "@mui/icons-material/NaturePeople";
import ScheduleIcon from "@mui/icons-material/Schedule";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import BadgeIcon from "@mui/icons-material/Badge";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import PreviewIcon from "@mui/icons-material/Preview";
import PolicyIcon from "@mui/icons-material/Policy";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { Box, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import { DRAWER_WIDTH } from "../utils/constants";
import TableViewIcon from "@mui/icons-material/TableView";
import { ExpoTheme } from "../themes/ExpoTheme";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import EmailIcon from "@mui/icons-material/Email";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import GrassIcon from "@mui/icons-material/Grass";
import { useTheme } from "@emotion/react";
import StorageIcon from "@mui/icons-material/Storage";
const iconMap = {
  AppSettingsAltIcon: AppSettingsAltIcon,
  SettingsIcon: SettingsIcon,
  EditIcon: EditIcon,
  DashboardIcon: DashboardIcon,
  ExploreIcon: ExploreIcon,
  GroupIcon: GroupIcon,
  CategoryIcon: CategoryIcon,
  BusinessCenterIcon: BusinessCenterIcon,
  PeopleAltIcon: PeopleAltIcon,
  MapIcon: MapIcon,
  ServicesIcon: ServicesIcon,
  BookIcon: BookIcon,
  ScheduleIcon: ScheduleIcon,
  FavoriteIcon: FavoriteIcon,
  AssessmentIcon: AssessmentIcon,
  SettingsIcon: SettingsIcon,
  LogoutIcon: LogoutIcon,
  NaturePeopleIcon: NaturePeopleIcon,
  ContactSupportIcon: ContactSupportIcon,
  TableViewIcon: TableViewIcon,
  DriveFileRenameOutlineIcon: DriveFileRenameOutlineIcon,
  BadgeIcon: BadgeIcon,
  PolicyIcon: PolicyIcon,
  PreviewIcon: PreviewIcon,
  MobileFriendlyIcon: MobileFriendlyIcon,
  EmailIcon: EmailIcon,
  AddBusinessIcon: AddBusinessIcon,
  DynamicFormIcon: DynamicFormIcon,
  GrassIcon: GrassIcon,
  StorageIcon: StorageIcon,
};
const menuItems = [
  {
    text: "Dashboard",
    link: "/home",
    icon: "DashboardIcon",
  },
  {
    text: "OES Forms",
    link: "/home/oes-forms",
    icon: "TableViewIcon",
  },

  {
    text: "Visitor Form",
    link: "#",
    icon: "DynamicFormIcon",
    subcategories: [
      {
        text: "Create the Form",
        link: "/home/registration-form-builder",
        icon: "DriveFileRenameOutlineIcon",
      },
      {
        text: "Settings",
        link: "/home/registration-settings",
        icon: "SettingsIcon",
      },
      {
        text: "Preview",
        link: "/home/registration-page-preview",
        icon: "PreviewIcon",
      },
      {
        text: "Terms & Conditions",
        link: "/home/registration-terms-conditions",
        icon: "PolicyIcon",
      },
      {
        text: "Badge Preview",
        link: "/home/registration-badge-preview",
        icon: "BadgeIcon",
      },
    ],
  },
  {
    text: "Floor Plan",
    link: "/home/floor-plan-creator",
    icon: "MapIcon",
  },
  {
    text: "Exhibitors",
    link: "#",
    icon: "NaturePeopleIcon",
    subcategories: [
      {
        text: "Exhibitor List",
        link: "/home/exhibitors-list",
        icon: "GroupIcon",
      },
      {
        text: "Add Exhibitor",
        link: "/home/add-exhibitor",
        icon: "AddBusinessIcon",
      },
    ],
  },
  {
    text: "Email templates",
    link: "/home/email-block-editor",
    icon: "EmailIcon",
  },
  {
    text: "Badge builder",
    link: "/home/badge-builder",
    icon: "BadgeIcon",
  },
  {
    text: "Mobile Apps",
    link: "/home/mobile-apps",
    icon: "MobileFriendlyIcon",
  },
  {
    text: "Onsite Module",
    link: "/home/onsite-module",
    icon: "GrassIcon",
  },
  {
    text: "Database",
    link: "/home/export-data",
    icon: "StorageIcon",
  },
];

const SideMenuBar = ({ open, setOpen }) => {
  const theme = useTheme();
  const [openSubmenu, setOpenSubmenu] = useState({});
  const drawerRef = useRef(null); // Create a reference to the Drawer

  const handleClickSubmenu = (submenu) => {
    setOpenSubmenu((prevOpen) => ({
      ...prevOpen,
      [submenu]: !prevOpen[submenu],
    }));
    setOpen(true);
  };

  const renderMenuItems = (items, nestingLevel = 0) =>
    items.map((item) => {
      const paddingLeftUnits = open ? 2 + nestingLevel * 2 : 1;
      const listItem = (
        <ListItemButton
          onClick={() =>
            item.subcategories ? handleClickSubmenu(item.text) : null
          }
          component={item.link ? RouterLink : "button"}
          to={item.link || ""}
          sx={{
            paddingLeft: theme.spacing(paddingLeftUnits),
            paddingY: theme.spacing(open ? 1 : 1.5),
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: open ? 40 : "auto",
              justifyContent: "center",
            }}
          >
            {iconMap[item.icon]
              ? React.createElement(iconMap[item.icon])
              : null}
          </ListItemIcon>
          {open && <ListItemText primary={item.text} />}
          {item.subcategories ? (
            openSubmenu[item.text] ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : null}
        </ListItemButton>
      );

      return (
        <React.Fragment key={item.text}>
          {open ? (
            listItem
          ) : (
            <Tooltip title={item.text} placement="right">
              {listItem}
            </Tooltip>
          )}
          {item.subcategories && (
            <Collapse in={openSubmenu[item.text]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderMenuItems(item.subcategories, nestingLevel + 1)}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      );
    });

  useEffect(() => {
    if (open) {
      const handleClickOutside = (event) => {
        if (drawerRef.current && !drawerRef.current.contains(event.target)) {
          setOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [open, setOpen]);

  useEffect(() => {
    if (!open) {
      setOpenSubmenu({}); // Close all submenus
    }
  }, [open]);

  return (
    <Drawer
      ref={drawerRef} // Attach the reference here
      variant="permanent"
      sx={{
        width: open ? DRAWER_WIDTH : theme.spacing(7),
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: open ? DRAWER_WIDTH : theme.spacing(7),
          boxSizing: "border-box",
          overflowX: "hidden",
        },
      }}
      open={open}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            height: "64px",
            display: "flex",
            alignItems: "center",
            padding: "0 8px",
            justifyContent: open ? "flex-start" : "center",
            "@media (max-width: 600px)": {
              height: "56px",
            },
          }}
        >
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          {open && (
            <Box
              component="img"
              sx={{
                ml: 2,
                maxWidth: "100%",
                height: "64px",
              }}
              src={"https://www.imtex.in/images/imtex-2025.png"}
              alt="Logo"
            />
          )}
        </Box>
        <Divider />
        <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
          <List>{renderMenuItems(menuItems)}</List>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SideMenuBar;

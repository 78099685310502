import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Paper,
  Avatar,
  Container,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import BackBtn from "../../widgets/BackBtn";

const ExhibitorForm = () => {
  const [formValues, setFormValues] = useState({
    user_id: "",
    username: "",
    companyName: "",
    contactSalutation: "",
    contactFirstName: "",
    contactLastName: "",
    contactDesignation: "",
    mobile: "",
    companyEmail: "",
    contactEmail: "",
    address1: "",
    address2: "",
    address3: "",
    country: "",
    state: "",
    city: "",
    pin: "",
    phone: "",
    fax: "",
    website: "",
    companyShortName: "",
    fascia: "",
    weAre: "",
    industrySector: [],
    exhibitorCategory: "",
    productCategories: [],
    hallNumber: "",
    standNumber: "",
    spaceType: "",
    exhibitArrival: null,
    exhibitArrivalTimeFrom: null,
    exhibitArrivalTimeTo: null,
    materialArrival: null,
    materialArrivalTimeFrom: null,
    materialArrivalTimeTo: null,
    dismantling: null,
    dismantlingTimeFrom: null,
    dismantlingTimeTo: null,
    standSize: "",
    profile_image: null,
    profile_banner: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDateChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleFileChange = (name, file) => {
    setFormValues({ ...formValues, [name]: file });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to backend API
    console.log("Form submitted:", formValues);
  };

  // Placeholder options for select fields
  const salutationOptions = ["Mr.", "Mrs.", "Ms.", "Dr.", "Prof."];
  const weAreOptions = ["Manufacturer", "Service Company", "Distributor"];
  const industrySectorOptions = [
    "Academia and Technical Institutions",
    "Automotive",
    "Aerospace",
    // ... other sectors
  ];
  const exhibitorCategoryOptions = ["PMTX 2024", "IMTEX 2024", "Other"];
  const hallNumberOptions = ["Hall 1", "Hall 2", "Hall 3"];
  const spaceTypeOptions = ["Shell Space", "Raw Space"];
  const countryOptions = [
    { code: "IN", name: "India" },
    { code: "US", name: "United States" },
  ];
  const stateOptions = [
    { code: "TN", name: "Tamil Nadu" },
    { code: "KA", name: "Karnataka" },
  ];
  const cityOptions = [{ name: "Thiruvallur" }, { name: "Bangalore" }];

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <BackBtn />
        <Container maxWidth="md">
          <Box component={Paper} sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom>
              Add Exhibitor
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* User ID and Username */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="User ID"
                    name="user_id"
                    value={formValues.user_id}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Username"
                    name="username"
                    value={formValues.username}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                {/* Company Details */}
                <Grid item xs={12}>
                  <TextField
                    label="Company Name"
                    name="companyName"
                    value={formValues.companyName}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                {/* Contact Person */}
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth required>
                    <InputLabel>Salutation</InputLabel>
                    <Select
                      label="Salutation"
                      name="contactSalutation"
                      value={formValues.contactSalutation}
                      onChange={handleInputChange}
                    >
                      {salutationOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4.5}>
                  <TextField
                    label="First Name"
                    name="contactFirstName"
                    value={formValues.contactFirstName}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4.5}>
                  <TextField
                    label="Last Name"
                    name="contactLastName"
                    value={formValues.contactLastName}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                {/* Contact Designation */}
                <Grid item xs={12}>
                  <TextField
                    label="Contact Designation"
                    name="contactDesignation"
                    value={formValues.contactDesignation}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                {/* Contact Information */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Mobile"
                    name="mobile"
                    value={formValues.mobile}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Phone"
                    name="phone"
                    value={formValues.phone}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Company Email"
                    name="companyEmail"
                    value={formValues.companyEmail}
                    onChange={handleInputChange}
                    type="email"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Contact Email"
                    name="contactEmail"
                    value={formValues.contactEmail}
                    onChange={handleInputChange}
                    type="email"
                    fullWidth
                    required
                  />
                </Grid>
                {/* Address */}
                <Grid item xs={12}>
                  <TextField
                    label="Address Line 1"
                    name="address1"
                    value={formValues.address1}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Address Line 2"
                    name="address2"
                    value={formValues.address2}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Address Line 3"
                    name="address3"
                    value={formValues.address3}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                {/* Country, State, City */}
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth required>
                    <InputLabel>Country</InputLabel>
                    <Select
                      label="Country"
                      name="country"
                      value={formValues.country}
                      onChange={handleInputChange}
                    >
                      {countryOptions.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth required>
                    <InputLabel>State</InputLabel>
                    <Select
                      label="State"
                      name="state"
                      value={formValues.state}
                      onChange={handleInputChange}
                    >
                      {stateOptions.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth required>
                    <InputLabel>City</InputLabel>
                    <Select
                      label="City"
                      name="city"
                      value={formValues.city}
                      onChange={handleInputChange}
                    >
                      {cityOptions.map((option) => (
                        <MenuItem key={option.name} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Pin, Fax */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Pin/Zip"
                    name="pin"
                    value={formValues.pin}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Fax"
                    name="fax"
                    value={formValues.fax}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                {/* Website */}
                <Grid item xs={12}>
                  <TextField
                    label="Website"
                    name="website"
                    value={formValues.website}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                {/* Company Short Name, Fascia */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Company Short Name"
                    name="companyShortName"
                    value={formValues.companyShortName}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Fascia"
                    name="fascia"
                    value={formValues.fascia}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                {/* We Are */}
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel>We Are</InputLabel>
                    <Select
                      label="We Are"
                      name="weAre"
                      value={formValues.weAre}
                      onChange={handleInputChange}
                    >
                      {weAreOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Industry Sector */}
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel>Industry/Sector</InputLabel>
                    <Select
                      label="Industry/Sector"
                      name="industrySector"
                      value={formValues.industrySector}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          industrySector: e.target.value,
                        })
                      }
                      multiple
                    >
                      {industrySectorOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>Select one or more</FormHelperText>
                  </FormControl>
                </Grid>
                {/* Exhibitor Category */}
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel>Exhibitor Category</InputLabel>
                    <Select
                      label="Exhibitor Category"
                      name="exhibitorCategory"
                      value={formValues.exhibitorCategory}
                      onChange={handleInputChange}
                    >
                      {exhibitorCategoryOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Product Categories */}
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel>Product Categories</InputLabel>
                    <Select
                      label="Product Categories"
                      name="productCategories"
                      value={formValues.productCategories}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          productCategories: e.target.value,
                        })
                      }
                      multiple
                    >
                      {/* Assuming product categories are similar to industry sectors */}
                      {industrySectorOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>Select one or more</FormHelperText>
                  </FormControl>
                </Grid>
                {/* Hall Number, Stand Number, Space Type */}
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth required>
                    <InputLabel>Hall Number</InputLabel>
                    <Select
                      label="Hall Number"
                      name="hallNumber"
                      value={formValues.hallNumber}
                      onChange={handleInputChange}
                    >
                      {hallNumberOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Stand Number"
                    name="standNumber"
                    value={formValues.standNumber}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth required>
                    <InputLabel>Space Type</InputLabel>
                    <Select
                      label="Space Type"
                      name="spaceType"
                      value={formValues.spaceType}
                      onChange={handleInputChange}
                    >
                      {spaceTypeOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Stand Size */}
                <Grid item xs={12}>
                  <TextField
                    label="Stand Size"
                    name="standSize"
                    value={formValues.standSize}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                {/* Exhibit Arrival */}
                <Grid item xs={12} sm={4}>
                  <DatePicker
                    label="Exhibit Arrival Date"
                    value={formValues.exhibitArrival}
                    onChange={(value) =>
                      handleDateChange("exhibitArrival", value)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TimePicker
                    label="Exhibit Arrival Time From"
                    value={formValues.exhibitArrivalTimeFrom}
                    onChange={(value) =>
                      handleDateChange("exhibitArrivalTimeFrom", value)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TimePicker
                    label="Exhibit Arrival Time To"
                    value={formValues.exhibitArrivalTimeTo}
                    onChange={(value) =>
                      handleDateChange("exhibitArrivalTimeTo", value)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                  />
                </Grid>
                {/* Material Arrival */}
                <Grid item xs={12} sm={4}>
                  <DatePicker
                    label="Material Arrival Date"
                    value={formValues.materialArrival}
                    onChange={(value) =>
                      handleDateChange("materialArrival", value)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TimePicker
                    label="Material Arrival Time From"
                    value={formValues.materialArrivalTimeFrom}
                    onChange={(value) =>
                      handleDateChange("materialArrivalTimeFrom", value)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TimePicker
                    label="Material Arrival Time To"
                    value={formValues.materialArrivalTimeTo}
                    onChange={(value) =>
                      handleDateChange("materialArrivalTimeTo", value)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                  />
                </Grid>
                {/* Dismantling */}
                <Grid item xs={12} sm={4}>
                  <DatePicker
                    label="Dismantling Date"
                    value={formValues.dismantling}
                    onChange={(value) => handleDateChange("dismantling", value)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TimePicker
                    label="Dismantling Time From"
                    value={formValues.dismantlingTimeFrom}
                    onChange={(value) =>
                      handleDateChange("dismantlingTimeFrom", value)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TimePicker
                    label="Dismantling Time To"
                    value={formValues.dismantlingTimeTo}
                    onChange={(value) =>
                      handleDateChange("dismantlingTimeTo", value)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                  />
                </Grid>
                {/* Profile Image */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" gutterBottom>
                    Profile Image
                  </Typography>
                  <Box display="flex" alignItems="center">
                    {formValues.profile_image ? (
                      <Avatar
                        src={URL.createObjectURL(formValues.profile_image)}
                        sx={{ width: 80, height: 80, mr: 2 }}
                      />
                    ) : (
                      <Avatar sx={{ width: 80, height: 80, mr: 2 }} />
                    )}
                    <label htmlFor="profile_image">
                      <input
                        accept="image/*"
                        id="profile_image"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handleFileChange("profile_image", e.target.files[0])
                        }
                      />
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload
                      </Button>
                    </label>
                  </Box>
                </Grid>
                {/* Profile Banner */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" gutterBottom>
                    Profile Banner
                  </Typography>
                  <Box display="flex" alignItems="center">
                    {formValues.profile_banner ? (
                      <Avatar
                        variant="square"
                        src={URL.createObjectURL(formValues.profile_banner)}
                        sx={{ width: 80, height: 80, mr: 2 }}
                      />
                    ) : (
                      <Avatar
                        variant="square"
                        sx={{ width: 80, height: 80, mr: 2 }}
                      />
                    )}
                    <label htmlFor="profile_banner">
                      <input
                        accept="image/*"
                        id="profile_banner"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handleFileChange("profile_banner", e.target.files[0])
                        }
                      />
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload
                      </Button>
                    </label>
                  </Box>
                </Grid>
                {/* Submit Button */}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                  >
                    Submit Registration
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>
      </Box>
    </LocalizationProvider>
  );
};

export default ExhibitorForm;

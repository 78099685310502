import {
  Box,
  Button,
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { API_ENDPOINT } from "../../utils/constants";
import BackBtn from "../../widgets/BackBtn";
import CustomCircularP from "../../widgets/CustomCircularP";
import SearchBar from "../../widgets/searchBar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import { ScrollTableContainer } from "../../components/visitors/Visitors";
import ExportExhibitorsButton from "./ExportExhibitorsButton";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from "react-router-dom";
const Exhibitors = () => {
  const [reload, setReload] = useState(false);
  const [page, setPage] = useState(1);
  const [sq, setSq] = useState("");
  const navigate = useNavigate();
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}user/a/get-exhibitors?page=${page}&sq=${sq}&r=${reload}`
  );
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };
  const handleSearch = (e) => {
    const vl = e.target.value;
    setSq(vl); // Update sq state with search query
  };
  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

  const pageChange = (e, page) => {
    setPage(page);
  };
  return (
    <Box>
      <BackBtn />
      <CustomCircularP show={isLoading} />
      <Grid container mb={2} rowSpacing={2}>
        <Grid item md={2} alignContent={"center"}>
          <Stack direction={"row"} gap={2}>
            <Typography variant="h5">Exhibitors</Typography>
            <Typography alignSelf={"center"} variant="body1">
              count: {data?.totalItems}
            </Typography>
          </Stack>
        </Grid>
        <Grid item md={6}>
          <SearchBar
            placeholder={"Search by company name"}
            size="small"
            onChange={debouncedHandleSearch}
          />
        </Grid>
        <Grid item md={4} alignContent={"center"}>
          <Stack direction={"row"} gap={2}>
            <Box flex={1} display={"flex"} justifyContent={"end"}>
              <Button
                onClick={() => navigate("/home/add-exhibitor")}
                variant="outlined"
                startIcon={<AddIcon />}
              >
                Add
              </Button>
            </Box>
            <ExportExhibitorsButton />
          </Stack>
        </Grid>
      </Grid>
      <Box>
        <ScrollTableContainer component={Paper}>
          <Table stickyHeader aria-label="scrollable table">
            <TableHead>
              <TableRow>
                <TableCell>Photo</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Contact Person</TableCell>
                <TableCell>Contact Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Fascia</TableCell>
                <TableCell>Country, State, city</TableCell>
                <TableCell>Floor Allocated</TableCell>
                <TableCell>Industry Sector</TableCell>
                <TableCell>Pin</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    No data available.
                  </TableCell>
                </TableRow>
              ) : (
                data?.data?.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Avatar src={user.profile_image} alt={user.firstName} />
                    </TableCell>
                    <TableCell>
                      <Link to={`/home/exhibitor/${user._id}`}>
                        {user.companyName.value}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {user.contactSalutation.value}{" "}
                      {user.contactFirstName.value} {user.contactLastName.value}
                    </TableCell>
                    <TableCell>{user.contactEmail.value}</TableCell>
                    <TableCell>{user.phone.value}</TableCell>
                    <TableCell>{user.fascia?.value}</TableCell>
                    <TableCell>
                      {user.country?.value?.name},{user.state?.value?.name},
                      {user.city?.value?.name}
                    </TableCell>
                    <TableCell>
                      {user.hallNumber?.value} | {user.standNumber?.value}
                    </TableCell>
                    <TableCell>
                      {user.industrySector?.value?.join(", ")}
                    </TableCell>
                    <TableCell>{user.pin.value}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </ScrollTableContainer>
      </Box>

      <Box display="flex" justifyContent="center" mt={2} mb={2}>
        {data && (
          <Pagination
            page={page}
            onChange={pageChange}
            count={Math.ceil(data.totalItems / 30)}
          />
        )}
      </Box>
    </Box>
  );
};

export default Exhibitors;
